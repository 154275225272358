import React from "react";

const Content = () => {
    return (
        <>
            <main id="body-content">
                <section className="wide-tb-80">
                <div className="container">       
                        <div id="js-styl2-mosaic" className="cbp">
                            <div className="cbp-item design">
                                <div className="gallery-link">
                                <a href="projectsingle" className="txt-white"><i className="icofont-external-link"></i></a>
                                </div>
                                <a href="images/portfolio/fullwidth/img-1.jpg" className="cbp-caption cbp-lightbox" data-title="Lorem ipsum">
                                    <div className="cbp-caption-defaultWrap">
                                        <img src="images/portfolio/fullwidth/img-1.jpg" alt=""/>
                                    </div>
                                    <div className="cbp-caption-activeWrap">
                                        <div className="cbp-l-caption-alignCenter">
                                            <div className="cbp-l-caption-body">
                                                <i className="icofont-search icofont-2x txt-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="cbp-item design">
                                <div className="gallery-link">
                                <a href="projectsingle" className="txt-white"><i className="icofont-external-link"></i></a>
                                </div>
                                <a href="images/portfolio/fullwidth/img-2.jpg" className="cbp-caption cbp-lightbox" data-title="Lorem ipsum
                ">
                                    <div className="cbp-caption-defaultWrap">
                                        <img src="images/portfolio/fullwidth/img-2.jpg" alt=""/>
                                    </div>
                                    <div className="cbp-caption-activeWrap">
                                        <div className="cbp-l-caption-alignCenter">
                                            <div className="cbp-l-caption-body">
                                                <i className="icofont-search icofont-2x txt-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="cbp-item illustration">
                                <div className="gallery-link">
                                <a href="projectsingle" className="txt-white"><i className="icofont-external-link"></i></a>
                                </div>
                                <a href="images/portfolio/fullwidth/img-3.jpg" className="cbp-caption cbp-lightbox" data-title="Lorem ipsum
                ">
                                    <div className="cbp-caption-defaultWrap">
                                        <img src="images/portfolio/fullwidth/img-3.jpg" alt=""/>
                                    </div>
                                    <div className="cbp-caption-activeWrap">
                                        <div className="cbp-l-caption-alignCenter">
                                            <div className="cbp-l-caption-body">
                                                <i className="icofont-search icofont-2x txt-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="cbp-item photography">
                                <div className="gallery-link">
                                <a href="projectsingle" className="txt-white"><i className="icofont-external-link"></i></a>
                                </div>
                                <a href="images/portfolio/fullwidth/img-7.jpg" className="cbp-caption cbp-lightbox" data-title="Lorem ipsum
                ">
                                    <div className="cbp-caption-defaultWrap">
                                        <img src="images/portfolio/fullwidth/img-7.jpg" alt=""/>
                                    </div>
                                    <div className="cbp-caption-activeWrap">
                                        <div className="cbp-l-caption-alignCenter">
                                            <div className="cbp-l-caption-body">
                                                <i className="icofont-search icofont-2x txt-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="cbp-item identity">
                                <div className="gallery-link">
                                <a href="projectsingle" className="txt-white"><i className="icofont-external-link"></i></a>
                                </div>
                                <a href="images/portfolio/fullwidth/img-8.jpg" className="cbp-caption cbp-lightbox" data-title="Lorem ipsum
                ">
                                    <div className="cbp-caption-defaultWrap">
                                        <img src="images/portfolio/fullwidth/img-8.jpg" alt=""/>
                                    </div>
                                    <div className="cbp-caption-activeWrap">
                                        <div className="cbp-l-caption-alignCenter">
                                            <div className="cbp-l-caption-body">
                                                <i className="icofont-search icofont-2x txt-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="cbp-item business">
                                <div className="gallery-link">
                                <a href="projectsingle" className="txt-white"><i className="icofont-external-link"></i></a>
                                </div>
                                <a href="images/portfolio/fullwidth/img-9.jpg" className="cbp-caption cbp-lightbox" data-title="Lorem ipsum
                ">
                                    <div className="cbp-caption-defaultWrap">
                                        <img src="images/portfolio/fullwidth/img-9.jpg" alt=""/>
                                    </div>
                                    <div className="cbp-caption-activeWrap">
                                        <div className="cbp-l-caption-alignCenter">
                                            <div className="cbp-l-caption-body">
                                                <i className="icofont-search icofont-2x txt-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="cbp-item photography">
                                <div className="gallery-link">
                                <a href="projectsingle" className="txt-white"><i className="icofont-external-link"></i></a>
                                </div>
                                <a href="images/portfolio/fullwidth/img-10.jpg" className="cbp-caption cbp-lightbox" data-title="Lorem ipsum">
                                    <div className="cbp-caption-defaultWrap">
                                        <img src="images/portfolio/fullwidth/img-10.jpg" alt=""/>
                                    </div>
                                    <div className="cbp-caption-activeWrap">
                                        <div className="cbp-l-caption-alignCenter">
                                            <div className="cbp-l-caption-body">
                                                <i className="icofont-search icofont-2x txt-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="cbp-item business">
                                <div className="gallery-link">
                                <a href="projectsingle" className="txt-white"><i className="icofont-external-link"></i></a>
                                </div>
                                <a href="images/portfolio/fullwidth/img-11.jpg" className="cbp-caption cbp-lightbox" data-title="Lorem ipsum">
                                    <div className="cbp-caption-defaultWrap">
                                        <img src="images/portfolio/fullwidth/img-11.jpg" alt=""/>
                                    </div>
                                    <div className="cbp-caption-activeWrap">
                                        <div className="cbp-l-caption-alignCenter">
                                            <div className="cbp-l-caption-body">
                                                <i className="icofont-search icofont-2x txt-white"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>        
                </section>
            </main>
        </>
    );
};
export default Content;
